
  import { defineComponent } from 'vue'
  import Modal from '@/components/modals/Modal.vue'
  import CustomButton from '@/components/shared/CustomButton.vue'

  export default defineComponent({
    components: {
        Modal,
        CustomButton
      },

    setup(props ,{ emit }) {
        const closeModal = () => {
            emit("closeModal")
          }

        return {
            closeModal
          }
      }
  })
