
  import { defineComponent , reactive} from 'vue'
  import Navigation from '@/components/Navigation.vue'

  export default defineComponent({
    components: {
        Navigation
    },

    setup() {
     const darkmode = reactive({
       enabled: true
     });

     const setMode = () => {
       darkmode.enabled = !darkmode.enabled;
       document.documentElement.className = darkmode.enabled ? "dark-theme" : "light-theme";
     }

     return {
       darkmode,
       setMode
     }
    },
  })
  