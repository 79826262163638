import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/Gallery.vue')
  },
  {
    path: '/image/:id',
    name: 'Image',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/Image.vue')
  },
  {
    path: '/articles',
    name: 'Articles',
    component: () => import(/* webpackChunkName: "about" */ '../views/Articles.vue'),
  },
  {
    path: '/articles/beginning-astrophotography-part1',
    name: 'Beginning-Astrophotography-Part1',
    component: () => import('../views/articles/beginning-astrophotography-part1.vue')
  },
  {
    path: '/articles/beginning-astrophotography-part2',
    name: 'Beginning-Astrophotography-Part2',
    component: () => import('../views/articles/beginning-astrophotography-part2.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    return {top: 0, behavior: 'smooth'}
  }
})

export default router
