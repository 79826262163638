
import { defineComponent , ref} from 'vue';
import WorkItem from '@/components/WorkItem.vue';
import HeadingComponent from '@/components/shared/HeadingComponent.vue';
import CustomButton from '@/components/shared/CustomButton.vue';
import ContactModal from '@/components/modals/ContactModal.vue';
import InfoBox from '@/components/shared/InfoBox.vue'
import Footer from '@/components/Footer.vue'

export default defineComponent({
  name: 'Home',
  components: {
    WorkItem,
    HeadingComponent,
    CustomButton,
    ContactModal,
    InfoBox,
    Footer
  },

  setup()
  {
    const showContactModal = ref(false)
    const openContactModal = () => {
        showContactModal.value = true
    }

    const closeModal = () => {
      showContactModal.value = false
    }

    return {
        showContactModal,
        openContactModal,
        closeModal
    }
  }

});
