import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  ref: "modal",
  class: "relative flex flex-col w-full max-w-lg p-8 rounded gap-4 max-h-8/10 drop-shadow-md",
  style: {"background-color":"var(--background-color-primary)"},
  dal: ""
}
const _hoisted_2 = { class: "text-2xl" }
const _hoisted_3 = { class: "overflow-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Backdrop = _resolveComponent("Backdrop")!

  return (_openBlock(), _createBlock(_component_Backdrop, {
    ref: "backdrop",
    class: "backdrop"
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "header")
        ]),
        _createElementVNode("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "close-button"),
          _renderSlot(_ctx.$slots, "default")
        ]),
        _createElementVNode("div", null, [
          _renderSlot(_ctx.$slots, "action-buttons")
        ])
      ], 512)
    ]),
    _: 3
  }, 512))
}