<template>
    <div>
      <div class="text-xl work">
        <span style="color: var(--accent-color)">
          //
        </span>
        <slot name="title"></slot>
      </div>
    </div>
  </template>

<style scoped>
 
.work{
  position: relative;
  display: inline-block;
}
.work::after{
  position: absolute;
  content: "";
  width: 100%;
  height: 5px;
  background: var(--accent-color);
  top: 100%;
  left: 0;
  border-radius: 25px;
}
 </style> 
