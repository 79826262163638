import { createApp } from 'vue'
import App from './App.vue'
import "./assets/styles.scss";
import router from './router'
import './index.css'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLightbulb } from "@fortawesome/free-solid-svg-icons";
import { faMoon } from "@fortawesome/free-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faLightbulb, faMoon, faLinkedin, faEnvelope);

createApp(App)
.component("font-awesome-icon", FontAwesomeIcon)
.use(router).mount('#app')
