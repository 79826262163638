
  import { defineComponent , ref} from 'vue'

  export default defineComponent({
    setup(){
      let articlesVisible = ref(false)

        const onOver = () => {
          articlesVisible.value = true
        }

        const onLeave = () => {
          articlesVisible.value = false
        }

        return {
            onOver,
            articlesVisible,
            onLeave
        }
      }
  })
  