import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full h-full main" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "max-w-6xl m-auto mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.setMode && _ctx.setMode(...args))),
      class: "absolute w-10 p-2 text-black rounded-lg right-2 top-2",
      style: {"background-color":"var(--link-color)"}
    }, [
      _createVNode(_Transition, {
        name: "fade",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_ctx.darkmode.enabled)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'lightbulb'] })
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'moon'] })
              ]))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_Navigation),
      _createVNode(_component_router_view, { class: "mt-5 ml-2 mr-2" }, {
        default: _withCtx(({ Component }) => [
          _createVNode(_Transition, {
            name: "fade",
            mode: "out-in"
          }, {
            default: _withCtx(() => [
              (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      })
    ])
  ]))
}